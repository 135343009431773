export default function Footer() {
  return (
    <div className='footer'>
      <ul className='footer__list'>
        <li className='list-item'>
          <a href='/about'>about</a>
        </li>
        {/* <li className='list-item'>contact</li> */}
      </ul>
    </div>
  );
}
